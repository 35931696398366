import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TokenInterceptor } from './interceptor/token-interceptor';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';

import { DashboardSidebarComponent } from './components/dashboard-sidebar/dashboard-sidebar.component';
import { AuthGuard } from './guards/auth.guard';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgxMaskModule } from 'ngx-mask';
import { DemoNgZorroAntdModule } from './ng-zorro-antd.module';
registerLocaleData(en);

@NgModule({
  declarations: [DashboardSidebarComponent, AppComponent],
  imports: [
    BrowserModule,
    DemoNgZorroAntdModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzPageHeaderModule,
    NzModalModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    AuthGuard,
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
