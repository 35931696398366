import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public userData = new BehaviorSubject({});
  public userInfo: any = [];

  constructor(private _http: HttpClient) {
    this.userData.subscribe((res) => {
      this.userInfo = res;
    });
  }

  login = (credential: any) => {
    return this._http.post<any>(environment.API_URL + 'login', credential);
  };

  changePassword = (email: any) => {
    return this._http.post<any>(environment.API_URL + 'forgot-password', email);
  };

  getTokenData = (token) => {
    return this._http.post<any>(environment.API_URL + 'check-reset-token', {
      token: token,
    });
  };

  logout = () => {
    return this._http.post<any>(environment.API_URL + 'logout', '');
  };

  resetPassword = (resetPassswordData: any) => {
    return this._http.post<any>(
      environment.API_URL + 'reset-password',
      resetPassswordData
    );
  };

  getLocalAuthData() {
    const userdata = localStorage.getItem('userData');
    var AuthUserData: any = JSON.parse(userdata);

    return AuthUserData;
  }

  clearLocalStorage() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userData');
    localStorage.removeItem('general');
  }

  getAuthUser = () => {
    return this._http.get<any>(
      environment.API_URL + 'get-auth-user'
    );
  };
}
