import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { HelperService } from '../service/helperservice.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private helper: HelperService
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    if (
      localStorage.getItem('accessToken') &&
      localStorage.getItem('accessToken') != ''
    ) {
      return true;
    } else {
      this.authService.clearLocalStorage();

      this.router.navigate(['/', 'auth', 'login']);
      return false;
    }
  }

  canActivateChild(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    if (
      localStorage.getItem('accessToken') &&
      localStorage.getItem('accessToken') != ''
    ) {
      const AuthUserData = this.authService.getLocalAuthData();
      let flag = true;
      if (_route.data.permissions.length>0){
        _route.data.permissions.forEach(element => {
          if (this.helper.findPermission(element)) {
            flag = false;
          }
        });
      }else{
        flag = false;
      }      

      if (
        (_route.data.roles &&
          _route.data.roles.indexOf(AuthUserData.roleName) === -1) || flag
      ) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    } else {
      this.authService.clearLocalStorage();
      this.router.navigate(['/', 'auth', 'login']);
      return false;
    }
  }
}
