import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private router: Router, private service: AuthenticationService) {} //private message: NzMessageService {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (localStorage.getItem('accessToken')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            if (event.body.message) {
              // this.toaster.pop('success', event.body.message, '');
            }
          }
        },
        (error) => {
          if (error.status === 401) {
            this.service.clearLocalStorage();

            // this.message.create('error', error.message);
            this.router.navigate(['/', 'auth', 'login']);
          }
          if (error.status === 403) {
            this.service.clearLocalStorage();
            // this.message.create('error', error.message);
            this.router.navigate(['/', 'auth', 'login']);
          }
          if (error.status === 500) {
            // this.message.create('error', 'Internal Server Error!');
          }
        }
      )
    );
  }
}
