export const environment = {
  production: true,
  BASE_URL: 'https://api.starwooddistributors.com/',
  API_URL: 'https://api.starwooddistributors.com/api/',
  STORAGE_URL: 'https://api.starwooddistributors.com/storage/',
  ERROR_MSG: 'Something went wrong!',
  VALIDATION_ERROR_MSG: 'Please fill all the required fields!',
  NOT_FOUND: 'No result found!',
  DISCOUNT_MSG: 'Please enter valid discount amount',
};

export const constants = {
  sheetContract: {
    expoxy_garage_contract: 'contracts/expoxy_garage_contract/',
    roller_shade_contract: 'contracts/roller_shade_contract/',
    plantation_shutter_quote: 'contracts/plantation_shutter_quote/',
    custom_roller_shade_contract: 'contracts/custom_roller_shade_contract/',
    custom_roman_shade_contract: 'contracts/custom_roman_shade_contract/',
    engineered_floor_quote: 'contracts/engineered-floor-quote/',
    honey_comb_shade_quote: 'contracts/honey-comb-shade-quote/',
    carpet_installation_quote: 'contracts/carpet_installation_quote/',
    blinds_quote: 'contracts/blinds_quote/',
  },

  materials: {
    plantation_shutter_material: 'material/plantation_shutter_material/',
  },

  general: {
    address: '323 Hilltop Ave, Richardson, TX 75081',
    phone: 'Phone: (972) 464-9793',
    company: 'STARWOOD DISTRIBUTORS',
    fax: '214.341.5200 Fax',
  },
};
